.quoteModuleContainer {
    
}

.quotes {
    min-height: 400px;
    text-align: left;
    color: white;
    margin-bottom: 20px;
}

.quotes-container {
    background-color:  rgba(30, 59, 63, 0.6);
    opacity: 0,50;
    padding-right: 5%;
    padding-top: 6%;
    padding-bottom: 6%;
    border-radius: 5px;
    margin-left: 3%;
    margin-right: 3%;
}