body {
  background-color: #000000;
  background-image: url("https://web.kope.cl/images/kp_logo.jpg");
  margin: 0 15px 0 15px; 
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: -30px 10px;
}

.App {
  margin:0;
}
