.menu {
    text-align: right;
    margin-bottom: 10px;
    border-width: 1px 0px 0px 0px;
    padding: 10px;
    border-color: rgb(108, 4, 4);
}

.kp-button {
    background-color:  rgba(23, 162, 184, 0.3);
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 5px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
}
.menu-border {
    min-width: 100px;
    min-height: 3px;
    border-bottom: 1px;
    border-color: red;
}