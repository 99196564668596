.descriptionContainer {
    min-height: 30px;
    padding-left: 30%;
}

.descriptionText {
    color: gray;
}

.text-border {
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
}