.wishlist-container {
    color: white;
	padding: 20px;
    min-width: 500px;
    border-width: 2px;
    border-color: rgb(56, 8, 8);
    margin-bottom: 20px;
    border-radius: 5px;
    margin-top: 20px;
    min-height: 500px;
}

.wish-title {
    font-size: 20px;
    font-weight: bold;
    text-shadow: 2px 2px #17a2b8;
}