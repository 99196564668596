.error404Container {
    margin-top: 30px;
    color: white;
}

.errorText {
    font-weight: bold;
    font-size: 30px;
    padding: 20px;
    min-height: 200px;
    text-align: center;
}