.photosContainer {
    padding: 20px;
    min-height: 300px;
}

.min-height-20 {
    min-height: 20px;
}

.min-height-40 {
    min-height: 40px;
}

.borde-rojo {
    border-color: rgb(91, 1, 1);
    min-width: 100px;
}