
.titleContainer {
    min-height: 50px;
}

.titleTextHeader  {
    color: white;
    font-size: 28px;
    font-style: italic;
    padding: 0px 20px 0px 20px;
    border-radius: 5px;
    text-align: right;
    border-width: 0px 1px 0px 0px;
    border-color: red;
}

