h1 {
    color: whitesmoke;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.blogContainer {
    min-height: 400px;
}

.textBlogHeader  {
    color: white;
    font-size: 30px;
    font-style: italic;
    padding: 0px 20px 0px 20px;
    border-radius: 5px;
    text-align: right;
}

.blogHeader {
	margin-bottom: 20px;
}

.blogPost {
    margin-bottom: 30px;
}

.postTitle {
    color: white;
    font-size: x-large;
}

.postContent {
    color: white;
    border-color: darkred;
    border-width: 3px 0px 0px 0px;
    padding: 20px;
}

.postDate {
    color: grey;
    font-size: 11px;
    padding: 20px;
    text-align: right;
}

.postBackgroud {
    background-color:  rgba(60, 60, 60, 0.8);
}